import { useRef, useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation, useBranch, useTrigger } from "@circle/gestalt-app";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Tooltip as CustomTooltip } from "../Tooltip";
import { useParams, useSearchParams } from "react-router-dom";
import { Icon } from "@circle/kip-components";

import styles from "./observationgraph.module.scss";
import { resolveClassNames } from "palstek";

const ObservationGraph = props => {
    const { translate }     = useTranslation();
    const { trigger }       = useTrigger();
    const wrapper           = useRef();
    const [searchParams]    = useSearchParams();
    const params = useParams();
    const [width, setWidth] = useState(0);
    const [showDuration, toggleDuration]   = useState(true);
    const [showFrequency, toggleFrequency] = useState(true);
    const { data }          = useBranch({
        data: ["observation", "graph"]
    });

    useLayoutEffect(() => {
        const rect = wrapper.current.getBoundingClientRect();

        setWidth(rect.width);
    }, []);

    useEffect(() => {
        if(!props.active?.id) return;

        const [sign, descr] = props.active?.referenceObject?.split(" | ") || [];

        trigger("fetchObsGraph", {
            plant_id:              props.active.plant_id, // eslint-disable-line camelcase
            messageType:           props.active.messageType,
            system:                props.active.system,
            message:               props.active.message,
            referenceObject:       props.active.referenceObject,
            messageGroup:          params.messageGroup === "Gesamtanlage" ? "overall" : params.messageGroup ?? "overall",
            referenceObject_Descr: descr, // eslint-disable-line id-length,camelcase
            referenceObject_Sign:  sign // eslint-disable-line camelcase
        });
    }, [props.active?.id, searchParams]);

    return (
        <div className={styles.graph}>
            <div className={styles.legend}>
                <div className={resolveClassNames(styles.duration, showDuration && styles.active)}>
                    <div className={resolveClassNames(styles.icon, styles.durationIcon)} onClick={() => toggleDuration(!showDuration)}>
                        <Icon _icon="BarChart"/>
                    </div>
                    <span>{ translate("observation.duration") }</span>
                </div>
                <div className={resolveClassNames(styles.frequency, showFrequency && styles.active)}>
                    <span>{ translate("observation.frequency") }</span>
                    <div className={resolveClassNames(styles.icon, styles.frequencyIcon)} onClick={() => toggleFrequency(!showFrequency)}>
                        <Icon _icon="LineChart"/>
                    </div>
                </div>
            </div>
            <div ref={wrapper} className={resolveClassNames(styles.graphChart, "full-width")}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={width}
                        height={100}
                        data={data.map(x => ({ ...x, frequency: parseInt(x.frequency, 10), duration: parseInt(x.duration, 10) }))}
                        margin={{
                            top:    20,
                            right:  -20,
                            bottom: 20,
                            left:   0
                        }}
                    >
                        <CartesianGrid stroke="#979797" fill="#f5f5f5" fillOpacity="0.25" strokeDasharray="4 4" vertical={false}/>
                        <XAxis dataKey="id" axisLine={false} tickLine={false}/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <YAxis yAxisId="left" orientation="left" axisLine={false} tickLine={false} tickFormatter={value => `${Math.floor(value / 60)} min`}/>
                        <YAxis yAxisId="right" orientation="right" axisLine={false} tickLine={false} allowDecimals={false}/>
                        <Bar name={translate("observation.tooltip.duration")} yAxisId="left" dataKey="duration" barSize={20} fill="#888" hide={!showDuration}/>
                        <Line name={translate("observation.frequency")} yAxisId="right" type="monotone" dataKey="frequency" stroke="#222" hide={!showFrequency}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

ObservationGraph.propTypes = {
    active: PropTypes.object
};

export { ObservationGraph };
