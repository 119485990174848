import { hasPermission, useBranch, useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { Button, Icon } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import styles from "./messageOverview.module.scss";

const MessageOverview = props => {
    const { trigger } = useTrigger();
    const { selectedMessageTypes, summary, user } = useBranch({
        selectedMessageTypes: ["queryOptions", "messageTypes"],
        summary:              ["summary"],
        user:                 ["user"]
    });

    const onClick = (e, msg) => {
        e.preventDefault();
        if(props.monitor) return trigger("onMessageTypeFilter", msg);
        return props.onClick(msg);
    };

    return (
        <div className={styles.overviewItems}>
            <Button className={resolveClassNames(styles.overviewButton, styles.errorButton)} aria-pressed={props.monitor ? selectedMessageTypes.includes("err") : props.err}
                onClick={e => onClick(e, "err")}>
                <Icon className={styles.overviewIcon} _icon="Error" />
                <span className={styles.overviewLabel}>{summary.err.count ?? 0}</span>
            </Button>
            <Button className={resolveClassNames(styles.overviewButton, styles.warnButton)} aria-pressed={props.monitor ? selectedMessageTypes.includes("warn") : props.warn}
                onClick={e => onClick(e, "warn")}>
                <Icon className={styles.overviewIcon} _icon="Warning" />
                <span className={styles.overviewLabel}>{summary.warn.count ?? 0}</span>
            </Button>
            <Button className={resolveClassNames(styles.overviewButton, styles.infoButton)} aria-pressed={props.monitor ? selectedMessageTypes.includes("info") : props.info}
                onClick={e => onClick(e, "info")}>
                <Icon className={styles.overviewIcon} _icon="Info" />
                <span className={styles.overviewLabel}>{summary.info.count ?? 0}</span>
            </Button>
            {
                hasPermission(user, "MESSAGE_MONITOR_SHOW_LOGS") &&
                <Button className={resolveClassNames(styles.overviewButton, styles.logButton)} aria-pressed={props.monitor ? selectedMessageTypes.includes("log") : props.log}
                    onClick={e => onClick(e, "log")}>
                    <Icon className={styles.overviewIcon} _icon="Log" />
                    <span className={styles.overviewLabel}>{summary.log.count ?? 0}</span>
                </Button>
            }
        </div>
    );
};

MessageOverview.defaultProps = {
    monitor: false
};

MessageOverview.propTypes = {
    err:     PropTypes.bool,
    warn:    PropTypes.bool,
    info:    PropTypes.bool,
    log:     PropTypes.bool,
    monitor: PropTypes.bool,
    onClick: PropTypes.func
};

export { MessageOverview };
