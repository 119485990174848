import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Column } from "./Column";
import { Row } from "./Row";
import { InfiniteList } from "./InfiniteList";
import { BusyIndicator } from "../BusyIndicator";
import { resolveClassNames } from "palstek";
import { Icon } from "@circle/kip-components";

import styles from "./datatable.module.scss";

const Datatable = props => {
    const { translate } = useTranslation();
    const columns      = props.children.filter(elem => elem.type.prototype === Column.prototype);
    const filtered     = columns.filter(x => !x.props.hidden);
    const columnWidths = filtered.map(elem => props.hasActionsColumn || props.isEditable ? `${parseInt(elem.props.width, 10) - 10 / props.children.length}%` : elem.props.width);

    const onHeadlineClick = x => {
        if(!x.props.sortable) return null;

        return props.onHeadlineClick(x.props.filter || x.props.item);
    };

    return (
        <div className={resolveClassNames("datatable flex-column briddle-custom", props.className)}>
            <table className= "flex-column ft-body" style={{ overflow: "auto" }}>
                {
                    props.head &&
                    <thead>
                        <tr>
                            {
                                filtered.map((elem, idx) => (
                                    <th
                                        className={`${elem.props.className ?? ""} colored font-bold ${elem.props.sortable ? "sortable clickable" : ""}`}
                                        key={idx}
                                        style={{ width: columnWidths[idx] }}
                                        onClick={onHeadlineClick.bind(this, elem)}
                                    >
                                        <div className={resolveClassNames("pr-4", styles.rowHeader)}>
                                            <span>{ (translate(elem.props.title)).toUpperCase() }</span>
                                            {
                                                (elem.props.sortable && (elem.props.item || elem.props.filter) === props.sortingSettings?.selected) &&
                                                <Icon className={styles.filterArrow} _icon={props.sortingSettings.order === "asc" ? "ArrowUp" : "ArrowDown"} />
                                            }
                                        </div>
                                    </th>
                                ))
                            }
                            {
                                (props.actions || props.hasActionsColumn || props.isEditable) &&
                                <th className="placeholder" />
                            }
                        </tr>
                    </thead>
                }
                <InfiniteList
                    metadata={props.metadata}
                    onFetch={props.onFetch}
                    element="tbody"
                    placeholder={<tr className="empty"><td colSpan={filtered.length + 1}>{ translate("datatable.empty") }</td></tr>}
                    loader={() => <tr><td colSpan="7" width="100%"><BusyIndicator /></td></tr>}
                >
                    {
                        props.content.map((elem, idx) => (
                            <Row
                                id={elem.id}
                                isEditable={props.isEditable}
                                hasActionsColumn={props.hasActionsColumn}
                                hasDrawerColumn={props.hasDrawerColumn}
                                index={idx}
                                key={elem.id ? elem.id : `${Date.now()}-${idx}`}
                                content={elem}
                                draggable={props.draggable}
                                onDrop={props.onDrop}
                                onDetails={e => props.onDetails(elem, e)}
                                active={props.selectedMsg && props.selectedMsg === elem.id}
                                refs={props.refs}
                                onClick={props.onRowClick.bind(this, elem)}
                                onMove={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
                                actions={props.actions}
                                columnWidths={columnWidths}>
                                { filtered }
                            </Row>
                        ))
                    }
                </InfiniteList>
            </table>
        </div>
    );
};

Datatable.defaultProps = {
    head:            true,
    refs:            "id",
    onRowClick:      () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    onDelete:        () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    onHeadlineClick: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    onEditClick:     () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    onDetails:       () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    isDeletable:     true,
    className:       ""
};

Datatable.propTypes = {
    children:         PropTypes.node,
    className:        PropTypes.string,
    content:          PropTypes.arrayOf(PropTypes.object),
    isEditable:       PropTypes.bool,
    draggable:        PropTypes.bool,
    onDrop:           PropTypes.func,
    onDetails:        PropTypes.func,
    hasActionsColumn: PropTypes.bool,
    hasDrawerColumn:  PropTypes.bool,
    onHeadlineClick:  PropTypes.func,
    onRowClick:       PropTypes.func,
    metadata:         PropTypes.object,
    onFetch:          PropTypes.func,
    head:             PropTypes.bool,
    actions:          PropTypes.node,
    refs:             PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    selectedMsg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    sortingSettings: PropTypes.object
};

export { Datatable };
