import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./progressbar.module.scss";

const ProgressBar = props => {
    return (
        <div className={resolveClassNames(styles.progress, props.className)}>
            <div className={styles.bar} style={{ width: `${props.value}%` }}/>
        </div>
    );
};

ProgressBar.propTypes = {
    className: PropTypes.string,
    value:     PropTypes.number
};

export { ProgressBar };
