import { Icon } from "@circle/kip-components";
import PropTypes from "prop-types";

import styles from "./tendency.module.scss";
import { resolveClassNames } from "palstek";

const Tendency = props => {
    if(isNaN(props.value) || props.value === null) return;

    return (
        <div className={styles.tendencyContainer}>
            <div className={resolveClassNames(styles.tendency, props.value > 0 && styles.rising, props.value < 0 && styles.dropping, props.value === 0 && styles.equal)}>
                <Icon _icon="ArrowUp"/>
                <span>{ Math.abs(Math.round(props.value)) }%</span>
            </div>
        </div>
    );
};

Tendency.propTypes = {
    value: PropTypes.number
};

export { Tendency };
