import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Tendency } from "./Tendency";

import styles from "./overview.module.scss";
import { resolveClassNames } from "palstek";

const OverviewStat = props => {
    const { translate } = useTranslation();

    return (
        <div className={styles.stat}>
            <div className={styles.row}>
                <span className={resolveClassNames(styles.column, styles[props.type])}>{ translate(`overview.tile.${props.type}`) }</span>
                <span className={styles.column}></span>
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.large}>
                        {
                            props.data?.duration?.value >= 0 &&
                            <span>{ Math.floor(props.data.duration.value / 60).toString().padStart(2, 0) }:{ (props.data.duration.value % 60).toString().padStart(2, 0) }</span>
                        }
                        {
                            !props.data?.duration?.value && props.data?.duration?.value !== 0 &&
                            <span>-</span>
                        }
                        <span className={styles.timeUnit}>min</span>
                    </div>
                    <Tendency value={props.data?.duration?.tendency}/>
                </div>
                <div className={styles.column}>
                    <span className={styles.large}>{ props.data?.amount?.value ?? "-" }</span>
                    <Tendency value={props.data?.amount?.tendency}/>
                </div>
            </div>
            <div className={styles.row}>
                <span className={resolveClassNames(styles.column, styles.small)}>{ translate("overview.tile.duration") }</span>
                <span className={resolveClassNames(styles.column, styles.small)}>{ translate("overview.tile.amount") }</span>
            </div>
        </div>
    );
};

OverviewStat.propTypes = {
    type: PropTypes.string,
    data: PropTypes.object
};

export { OverviewStat };
