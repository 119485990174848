import PropTypes from "prop-types";
import { LoadingSpinner } from "@circle/kip-components";

const BusyIndicator = props => {
    return (
        <LoadingSpinner _threshhold={1000} _mode={props.withContainer ? "global" : "inline"} />
    );
};

BusyIndicator.propTypes = {
    withContainer: PropTypes.bool
};

export { BusyIndicator };
