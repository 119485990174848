import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { useDrop, useDrag } from "react-dnd";
import { DropdownToggle } from "../DropdownToggle";
import { Button, Icon } from "@circle/kip-components";

const Row = props => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const [more, setMore] = useState(false);
    const isAvailable     = Boolean(props.content.id);

    const [collectedProps, drop] = useDrop(() => ({
        accept:  "row",
        drop:    () => ({ id: props.content.id }),
        canDrop: x => !x.isActive,
        collect: monitor => ({
            isOver:  monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }));
    const [{ isDragging }, drag] = useDrag({
        type: "row",
        item: () => ({
            id: props.content.id
        }),
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if(!dropResult) return null;

            return props.onDrop(item.id, dropResult.id);
        }
    }, [props.content]);

    const className = `${props.className} row${!props.active ? " clickable" : " active"}${collectedProps.canDrop && collectedProps.isOver ? " drop-target blink" : ""}`;

    const onDelete = e => {
        e.stopPropagation(e);

        if(!isAvailable) return null;

        return props.onDelete(e);
    };
    const onMore = e => {
        e.stopPropagation(e);

        if(!isAvailable) return null;

        return setMore(true);
    };
    const onDetails = e => {
        e.stopPropagation(e);
        return props.onDetails(e);
    };

    return (
        <tr ref={node => drag(drop(node))} style={{ opacity: isDragging ? 0.4 : 1 }} className={className} onClick={props.onClick}>
            {
                props.children.map((x, index) => (
                    <td className={x.props.className} key={props.index * props.children.length + index} style={{ width: props.columnWidths[index] }}>
                        <div className="cell flex clamped pr-4">
                            {
                                x.props.render &&
                                x.props.render(!x.props.item ? props.content : props.content[x.props.item])
                            }
                            {
                                !x.props.render &&
                                <span>{props.content[x.props.item]}</span>
                            }
                        </div>
                    </td>
                ))
            }
            {
                props.actions &&

                <td>
                    {
                        React.cloneElement(props.actions, [].concat(props.refs).reduce((dest, elem) => ({ ...dest, [elem]: props.content[elem] }), {}))
                    }
                </td>

            }
            {
                !props.actions && (props.hasActionsColumn || props.isEditable) &&
                <td style={{ width: "10%", minWidth: "90px", justifyContent: "flex-end" }}>
                    <div className={`row-actions relative${isAvailable ? "" : " disabled"}`}>
                        {
                            props.isEditable &&
                            <div>
                                <img onClick={onDelete} width="44" height="44" className="trash" src="/icons/trashcan_v4.svg"/>
                                <img onClick={onMore} width="44" height="44" className="more" src="/images/ellipsis.png"/>
                            </div>
                        }
                        <DropdownToggle isOpen={more} onClose={() => setMore(false)}>
                            <a href="#" className="dropdown-link w-dropdown-link" tabIndex="0" onClick={ props.onEdit }>{ translate("actions.edit") }</a>
                            <a href="#" className="dropdown-link-2 w-dropdown-link" tabIndex="0" onClick={ props.onMove }>{ translate("actions.move") }</a>
                        </DropdownToggle>
                    </div>
                </td>
            }
            {
                !props.actions && props.hasDrawerColumn &&
                <td style={{ width: "10%", minWidth: "90px", justifyContent: "flex-end" }}>
                    <div className={`row-actions relative${isAvailable ? "" : " disabled"}`}>
                        <Button onClick={onDetails} _variant="icon">
                            <Icon _icon="Search" />
                        </Button>
                    </div>
                </td>
            }
        </tr>
    );
};

Row.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    children:         PropTypes.node,
    index:            PropTypes.number,
    content:          PropTypes.object,
    onClick:          PropTypes.func,
    onMove:           PropTypes.func,
    active:           PropTypes.bool,
    isEditable:       PropTypes.bool,
    onDelete:         PropTypes.func,
    hasActionsColumn: PropTypes.bool,
    hasDrawerColumn:  PropTypes.bool,
    onDetails:        PropTypes.func,
    onEdit:           PropTypes.func,
    columnWidths:     PropTypes.arrayOf(PropTypes.string),
    onDrop:           PropTypes.func,
    className:        PropTypes.string,
    actions:          PropTypes.node,
    refs:             PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
};

Row.defaultProps = {
    draggable: false,
    className: "",
    onClick:   x => x,
    onEdit:    x => x
};

export { Row };
