import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Icon } from "@circle/kip-components";
import PropTypes from "prop-types";

import styles from "./sortableheader.module.scss";
import { resolveClassNames } from "palstek";

const SortableHeader = props => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { sorting }   = useBranch({
        sorting: ["sorting"]
    });

    const onSort = column => {
        if(!props.isVisible) return;

        trigger("setSorting", column);
    };

    return (
        <div className={resolveClassNames(styles.column, props.isVisible && styles.visible)} onClick={() => onSort(props.type)}>
            {
                props.isVisible &&
                <>
                    <span>{ translate(`overview.type.${props.type}`) }</span>
                    {
                        sorting.mechanism !== "hidden" && sorting.column === props.type &&
                        <Icon className={styles.icon} _icon={sorting.mechanism === "asc" ? "ChevronDown" : "ChevronUp" }/>
                    }
                </>
            }
        </div>
    );
};

SortableHeader.propTypes = {
    isVisible: PropTypes.bool,
    type:      PropTypes.string
};

export { SortableHeader };
