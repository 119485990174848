import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { ProcessGraph } from "./ProcessGraph";
import icons from "../../enums/icons";
import types from "../../enums/types";
import { useLocation, useParams } from "react-router-dom";
import { Button, Icon } from "@circle/kip-components";
import { ObservationGraph } from "../observation/ObservationGraph";
import { CauseReportsForm } from "../cause/CauseReports";
import { HistoryTable } from "../HistoryTable";
import { SidebarButtons } from "../../SidebarButtons";

const MonitorForm = props => { // eslint-disable-line complexity, max-statements
    const { trigger } = useTrigger();
    const params      = useParams();
    const tableRef    = useRef();
    const { translate, currentLanguage } = useTranslation();
    const { history, historyChrono, sidebarGraph, calendar, observations, selectedPlant } = useBranch({
        history:       ["sidebarHistory"],
        historyChrono: ["sidebarHistoryChrono"],
        sidebarGraph:  ["sidebarContent", "graph"],
        calendar:      ["queryOptions", "calendar"],
        observations:  ["observations"],
        selectedPlant: ["selectedPlant"]
    });

    const [formVariant, setFormVariant]             = useState("detailForm");
    const [tableSwitchFilter, setTableSwitchFilter] = useState("aggregated");

    const location   = useLocation();
    const fromString = calendar.from.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");
    const toString   = calendar.until.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");

    useEffect(() => {
        const from    = calendar.from.getTime() / 1000;
        const until   = calendar.until.getTime() / 1000;
        const filters = {
            startTime:   `[${from},${until}]`,
            plant_id:    params.plantId ?? location.pathname.split("?")[0].split("/").slice(-1)[0], // eslint-disable-line camelcase
            messageType: props.message.type,
            system:      props.message.system,
            message:     props.message.message,
            messageEx:   props.message.additionalText
        };
        const fullFilters = {
            ...filters,
            referenceObject_Descr: props.message.refObjDescr, // eslint-disable-line camelcase,id-length
            referenceObject_Sign:  props.message.refObjSign // eslint-disable-line camelcase,id-length
        };

        trigger("retrieve", "history", ["sidebarContent", "history", "messages"], fullFilters);
        trigger("retrieve", "history/list", ["sidebarContent", "history", "list"], fullFilters);
        trigger("retrieve", "sidebar/graph", ["sidebarContent", "graph"], fullFilters);
    }, []);


    const onClick = e => {
        e.stopPropagation();
        trigger("toggleObservation", {
            id:              props.message.id,
            plant_id:        params.plantId, // eslint-disable-line camelcase
            messageType:     props.message.type,
            system:          props.message.system,
            message:         props.message.message,
            referenceObject: `${props.message.refObjSign} | ${props.message.refObjDescr}`
        });
    };

    const isActive = Boolean(observations.find(x =>
        x.plant_id === params.plantId &&
        x.messageType === props.message.type &&
        x.system === props.message.system &&
        x.message === props.message.message &&
        x.referenceObject === `${props.message.refObjSign} | ${props.message.refObjDescr}`
    ));

    const onFormViewChange = val => {
        setFormVariant(val);
    };

    return (
        <div>
            <div className="form" ref={tableRef}>
                <div className="flex mb-2">
                    <div className="flex-column flex-grow flex-item">
                        <h5>
                            {translate(types[props.message.type])} <Icon className={`icon-message-${props.message.type}`} _icon={icons[props.message.type]}/>
                        </h5>
                        <span className="font-bold sidebar-subtitle mt-2">{ fromString } - { toString }</span>
                    </div>
                    <Button className="monitor-favorite-button" _variant="icon" onClick={onClick} _appearance={isActive ? "primary" : "default"}>
                        <Icon _icon={isActive ? "StarSolid" : "Star"}/>
                    </Button>
                </div>
                <div className="mb-3">
                    <h6>
                        {props.message.system}
                    </h6>
                    <div>
                        <b>
                            {props.message.referenceObject}
                        </b>
                    </div>
                </div>
                <div>
                    <h6 className="message-text-title">{translate("message.text").toUpperCase()}</h6>
                    <h6>{props.message.message}</h6>
                </div>
                <SidebarButtons onFormViewChange={onFormViewChange} formVariant={formVariant}/>

                { formVariant === "causeReports" &&
                    <CauseReportsForm
                        target={tableRef}
                        selectedPlant={selectedPlant}
                        selectedMessage={{ startTime: props.message?.startTime, endTime: props.message?.endTime, index: props.message?.id, messages: new Array({ ...props.message, index: props.message?.id }) }}
                        message={props.message}
                    />
                }
                { formVariant === "detailForm" &&
                    <>
                        <div className="flex form-row">
                            <div className="form-column flex-column flex-grow">
                                <span className="form-header font-bold">
                                    { translate("message.course") }
                                </span>
                                <ObservationGraph active={{
                                    id:              props.message.id,
                                    messageType:     props.message.type,
                                    system:          props.message.system,
                                    message:         props.message.message,
                                    messageEx:       props.message.additionalText,
                                    referenceObject: props.message.referenceObject
                                }}/>
                            </div>
                        </div>
                        <div className="flex form-row">
                            <div className="form-column flex-column flex-grow">
                                <span className="form-header font-bold">
                                    { translate("message.hourly.frequency") }
                                </span>
                                <ProcessGraph data={sidebarGraph}/>
                            </div>
                        </div>
                        <HistoryTable onSwitchTableFilter={val => setTableSwitchFilter(val)} tableSwitchFilter={tableSwitchFilter} history={history} historyChrono={historyChrono} />
                    </>
                }
            </div>
        </div>
    );
};

MonitorForm.propTypes = {
    id:              PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    plant_id:        PropTypes.string, // eslint-disable-line camelcase
    type:            PropTypes.string,
    system:          PropTypes.string,
    additionalText:  PropTypes.string,
    refObjDescr:     PropTypes.string,
    refObjSign:      PropTypes.string,
    referenceObject: PropTypes.string,
    startTime:       PropTypes.object,
    endTime:         PropTypes.object,
    duration:        PropTypes.string,
    locale:          PropTypes.string,
    message:         PropTypes.object
};

MonitorForm.defaultProps = {
    onClose: x => x,
    message: {}
};

export { MonitorForm };
