import { useRef, useLayoutEffect, useState } from "react";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar, Cell } from "recharts";
import { Tick } from "./Tick";
import { Tooltip as CustomTooltip } from "../Tooltip";
import { toDateString } from "../../helper/helper";

const getColor     = index => index % 2 === 0 ? "#8DE7AD" : "#7D7D7D";
const getPrevColor = index => index % 2 === 0 ? "#A7FFC7" : "#989898";

const Plot = () => {
    const wrapper           = useRef();
    const [width, setWidth] = useState(0);
    const { translate, currentLanguage } = useTranslation();
    const { evaluation, type, calendar } = useBranch({
        evaluation: ["evaluation", "messages"],
        type:       ["queryOptions", "type"],
        calendar:   ["queryOptions", "calendar"]
    });

    useLayoutEffect(() => {
        const rect = wrapper.current.getBoundingClientRect();

        setWidth(rect.width);
    }, [evaluation]); // eslint-disable-line

    const data = (evaluation ?? []).map(x => ({
        ...x,
        id: `${x.referenceObject}\n${x.message}`
    })).sort((a, b) => b[type] - a[type]);

    const formatDateValue = val => {
        return type === "duration" ? toDateString(val) : val;
    };

    const maxWidth       = data.reduce((dest, elem) => Math.max(dest, elem.referenceObject.length, elem.message.length), 0);
    const prevType       = `prev${type?.charAt(0).toUpperCase() + type?.slice(1)}`;
    const dateDiff       = (calendar.until.getTime() - calendar.from.getTime());
    const prevDateString = `(${new Date(calendar.from.getTime() - dateDiff).toLocaleString(currentLanguage, { timeZone: "UTC" })} - ${new Date(calendar.until.getTime() - dateDiff).toLocaleString(currentLanguage, { timeZone: "UTC" })})`;
    const maximumValue   = data.reduce((dest, val) => Math.max(dest, Number(val[prevType] || 0), Number(val[type])), 0);

    return (
        <div ref={wrapper} className="evaluation-graph full-width" style={{ height: 50 + 40 * evaluation.length }}>
            { evaluation.length > 0 && <ResponsiveContainer width="100%" height="100%">
                <BarChart width={width} height={50} data={data} layout="vertical" margin={{ left: Math.min(maxWidth, 30) * 7.5 }}>
                    <XAxis type="number" domain={[0, maximumValue]} tickFormatter={formatDateValue}/>
                    <YAxis width={200} type="category" interval={0} dataKey="id" tick={<Tick/>}/>
                    <Tooltip content={<CustomTooltip formatter={formatDateValue} />}/>
                    <Bar name={translate(`evaluation.${type}`)} barSize={10} dataKey={type} radius={[0, 20, 20, 0]} >
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={getColor(index)} />
                            ))
                        }
                    </Bar>
                    {
                        evaluation[0] && Number.isInteger(evaluation[0][prevType]) &&
                        <Bar name={`${translate(`evaluation.${prevType}`)} ${prevDateString}`} barSize={10} dataKey={prevType} radius={[0, 20, 20, 0]} >
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-prev-${index}`} fill={getPrevColor(index)} />
                                ))
                            }
                        </Bar>

                    }
                </BarChart>
            </ResponsiveContainer>}
        </div>
    );
};

export { Plot };
