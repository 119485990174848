import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger, hasPermission } from "@circle/gestalt-app";
import { MainGraph } from "./mainGraph/MainGraph";
import { Filter } from "./Filter";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import icons from "../../enums/icons";
import { MonitorForm } from "./MonitorForm";
import { FilteredList } from "./FilteredList";
import { DatatableMessage } from "../../types/DatatableMessage";
import { useParams } from "react-router-dom";
import { Icon, Drawer, OverflowContainer } from "@circle/kip-components";
import { useQuery } from "../../hooks/query";
import { useQueryString } from "../../hooks/querystring";
import { CauseModal } from "../cause/CauseModal";
import { Breadcrumb } from "../Breadcrumb";
import { RangeSelector } from "../generic/rangeSelector/RangeSelector";
import { getRangeString } from "../../helper/date";
import { MultiSelect } from "../generic/MultiSelect";
import { TypeSelector } from "../detail/TypeSelector";
import { MessagesStatisticCard } from "./messagesStatisticCard";
import { DatePicker } from "./../../types/DatePicker";
import { resolveClassNames, usePrevious } from "palstek";

import styles from "./monitor.module.scss";

const Monitor = props => { // eslint-disable-line complexity, max-statements
    const { translate, currentLanguage } = useTranslation();
    const { trigger }    = useTrigger();
    const [currentBreadcrumbs, setCurrentBreadcrumbs] = useState([]);
    const params         = useParams();
    // const [searchParams] = useSearchParams();
    const { monitorSortingSettings, metadata, monitor, plants, options, slctdPlant, user } = useBranch({
        monitorSortingSettings: ["monitorSortingSettings"],
        slctdPlant:             ["selectedPlant"],
        metadata:               ["pagination", "messages"],
        monitor:                ["monitor"],
        plants:                 ["plants"],
        options:                ["queryOptions"],
        user:                   ["user"]
    });

    const [visible, setVisible]   = useState(false);
    const [selected, setSelected] = useState(null);
    const [grouped, setGrouped]   = useState(null);
    const [initialCalendar, setInitialCalendar] = useState(options.calendar);
    const prevOpts = usePrevious(options);

    const [filter] = useQueryString({
        options:  options,
        plants:   plants,
        optional: {
            timeframes:         () => options.timeframes || "shifts",
            mode:               x => x ?? "filter",
            keyword:            x => x ? JSON.parse(x) : [],
            mainGraphViewTypes: x => JSON.parse(x?.replace("undefined", "null") ?? JSON.stringify(options.mainGraphViewTypes))
        }
    });
    const keys   = ["startTime", "keyword", "mode", "messageType", "mainGraphViewTypes", "range", "shift", "timeframes"];
    const isFilterEnabled = options.mode === "filter";

    const [init] = useQuery({
        options: filter,
        keys:    keys
    });

    const onRowClick = elem => {
        setVisible(true);
        setSelected(elem.id);
    };

    const onFetch = () => {
        const plant = params.plantId || props.selected?.id;

        if(!plant) return () => trigger("cleanup", "messages");

        trigger("pullMessages", plant, params.messageGroup, null, false);
        return () => trigger("cleanup", "messages");
    };

    const onSearch = values => {
        trigger("applyOption", "keyword", values.map(x => x.value));
    };

    const onTypeSelect = type => {
        if(options.messageTypes.includes(type) && options.messageTypes.length === 1) return;

        trigger("applyOption", "messageTypes", options.messageTypes.includes(type) ? options.messageTypes.filter(x => x !== type) : options.messageTypes.concat(type));
    };


    const onSelectFilter = elem => {
        setSelected(elem.item);
        setVisible(true);
        setGrouped(elem.id);
    };

    const onClose = () => {
        setVisible(false);
        setSelected(null);
        setGrouped(null);
    };

    const handleBreadcrumbClick = (range, index) => {
        const newDate = DatePicker.of([range.from, range.until]);

        setCurrentBreadcrumbs([...currentBreadcrumbs].slice(0, index + 1));
        trigger("applyOption", "calendar", newDate);
        trigger("applyOption", "range", newDate.name);
    };

    const handleGraphZoomIn = dateInfo => {
        const index = currentBreadcrumbs.length;

        const resolveTimeLabel = info => {
            const diff = new Date(info?.until).getTime() - new Date(info?.from).getTime();
            const hour = 1000 * 60 * 60;
            const day = hour * 24;
            const largestMonth = day * 31;

            if(diff > 367 * day) return `${new Date(info.from).getFullYear()} - ${new Date(info.until).getFullYear()}`;
            if(diff > largestMonth) return `${new Date(info.from).getFullYear()}`;
            if(diff <= largestMonth && diff > day) return `${new Date(info.from).toLocaleString(currentLanguage, { month: "long" })}`;
            if(diff <= day && diff > hour) return `${new Date(info.from).toLocaleDateString(currentLanguage)}`;
            if(diff <= hour) return `${new Date(info.from).getUTCHours()}`.padStart(2, 0).concat(":00");
            if(diff >= hour) return `${Math.floor(diff / hour)} hours`;

            return getRangeString([info.from, info.until], currentLanguage);
        };

        setCurrentBreadcrumbs([...currentBreadcrumbs, {
            value: resolveTimeLabel(dateInfo),
            index,
            info:  dateInfo
        }]);
    };

    const onDatePick = date => {
        trigger("applyOption", "range", date.name);
        trigger("applyOption", "calendar", date);

        setInitialCalendar(date);

        setCurrentBreadcrumbs([]);
    };

    const onTimeframeChange = val => {
        trigger("applyOption", "timeframes", val);
    };

    useEffect(() => {
        const isLazy = prevOpts?.messageTypes?.length !== options?.messageTypes?.length;

        trigger("fetchMonitorStatistic", slctdPlant?.id ?? params?.plantId, params?.messageGroup);
        trigger("fetchMonitorGraph", slctdPlant?.id ?? params?.plantId, params?.messageGroup, isLazy);
        trigger("fetchGroups");
        trigger("pullMessages", slctdPlant?.id ?? params?.plantId, params.messageGroup, {
            startTime:    `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            plant_id:     slctdPlant?.id ?? params?.plantId, // eslint-disable-line camelcase
            keyword:      options.keyword.map(x => encodeURIComponent(x)).filter(x => x).map(x => `"${x}"`),
            range:        options.range,
            messageGroup: params?.messageGroup === "Gesamtanlage" ? "overall" : params?.messageGroup,
            messageType:  options.messageTypes.map(elem => `"${elem}"`),
            order:        monitorSortingSettings.order,
            orderKey:     monitorSortingSettings.selected,
            timeframes:   options.timeframes
        }, true);
    }, [params.plantId, params.messageGroup, options, monitorSortingSettings]);

    const message = !isFilterEnabled ?
        props.messages.find(x => x.id === selected) :
        new DatatableMessage(Object.values(monitor.aggregates).reduce((dest, elem) => dest.concat(elem), []).find(elem => elem.id === grouped));

    const items = [
        { value: translate(slctdPlant?.name), path: `/overview/${slctdPlant?.id}`, index: 0 },
        { value: params.messageGroup, index: 1, onClick: () => handleBreadcrumbClick(initialCalendar, -1) }
    ];

    return (
        <>
            { init &&
            <div className="monitor flex-column">
                <div className={styles.headerView}>
                    <div className={styles.breadcrumb}>
                        <div className={styles.breadcrumbContainer}>
                            <Breadcrumb items={[
                                ...items,
                                ...[...currentBreadcrumbs].map(item => ({
                                    value:   item.value,
                                    index:   item.index,
                                    onClick: () => handleBreadcrumbClick(item.info, item.index)
                                }))
                            ]} />
                        </div>
                        <RangeSelector
                            range={options.range}
                            selected={options.calendar}
                            onChange={onDatePick}
                            timeframes={options.timeframes}
                            onTimeframeChange={onTimeframeChange}
                            workingShifts={options.selectedPlant.workingShifts}
                        />
                    </div>
                    <div className={styles.plant}>
                        <div className={styles.logo}>
                            <img src={slctdPlant?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.plantName}>
                                <span>{ translate(params?.messageGroup) }</span>
                                <span className={styles.slot}>{ options.range === "shift" ? translate("overview.selector.shift") : getRangeString([options.calendar.from, options.calendar.until], currentLanguage)}</span>
                            </div>
                            <span className={styles.manufacturer}>{ slctdPlant?.manufacturer?.name }</span>
                        </div>
                    </div>
                    <div className={styles.topHeaderContent}>
                        <div className={styles.header}>
                            <div className={styles.search}>
                                <MultiSelect onChange={onSearch}/>
                            </div>
                            <div className={styles.selector}>
                                <TypeSelector onClick={() => onTypeSelect("err")} type="err" active={options.messageTypes.includes("err")}/>
                                <TypeSelector onClick={() => onTypeSelect("task")} type="task" active={options.messageTypes.includes("task")}/>
                                <TypeSelector onClick={() => onTypeSelect("warn")} type="warn" active={options.messageTypes.includes("warn")}/>
                                <TypeSelector onClick={() => onTypeSelect("info")} type="info" active={options.messageTypes.includes("info")}/>
                                <TypeSelector onClick={() => onTypeSelect("system")} type="system" active={options.messageTypes.includes("system")}/>
                                {
                                    hasPermission(user, "MESSAGE_MONITOR_ADMIN") &&
                                <TypeSelector onClick={() => onTypeSelect("log")} type="log" active={options.messageTypes.includes("log")}/>
                                }
                            </div>
                        </div>
                        {/* <Button _variant="icon" className={styles.favoriteButton}>
                            <Icon _icon="Star"/>
                        </Button> */}
                    </div>
                </div>
                <div className={styles.graphContainer}>
                    <MainGraph data={props.graph} onGraphZoomIn={handleGraphZoomIn} />
                    <MessagesStatisticCard />
                </div>
                <div className={styles.datatableContainer}>
                    <div className="datatable-header p-5 flex">
                        <Filter onSelect={() => trigger("onFilter", params?.plantId, params?.messageGroup)} isActive={isFilterEnabled} translate={translate}/>
                        {props.selected && <CauseModal selectedPlant={props.selected} marginLeft={true} />}
                    </div>
                    {
                        !isFilterEnabled &&
                    <Datatable
                        content={props.messages}
                        sortingSettings={monitorSortingSettings}
                        onHeadlineClick={x => trigger("setMonitorSorting", x)}
                        infinite={true}
                        hasActionsColumn={false}
                        onRowClick={onRowClick}
                        selectedMsg={selected}
                        metadata={metadata}
                        onFetch={onFetch}
                        refs={["id", "system", "message", "additionalText", "referenceObject", "plant_id", "messageType"]}
                    >
                        <Column
                            sortable
                            title="message.messageType"
                            item="type"
                            width="100px"
                            filter="messageType"
                            render={x => <Icon className={`icon-message-${x}`} _icon={icons[x]}/>}
                        />
                        <Column
                            sortable
                            title="message.system"
                            item="system"
                            width="90px"
                            filter="system"
                        />
                        <Column
                            sortable
                            title="message.refObj"
                            item="referenceObject"
                            width="calc((100% - 670px) / 2)"
                            filter="referenceObject"
                        />
                        <Column
                            sortable
                            title="message.text"
                            item="message"
                            width="calc((100% - 570px) / 2)"
                            filter="message"
                        />
                        <Column
                            sortable
                            title="message.startTime"
                            item="startTime"
                            width="140px"
                            className="horizontal-end"
                            render={x => x.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "")}
                        />
                        <Column
                            sortable
                            title="message.endTime"
                            item="endTime"
                            width="140px"
                            className="horizontal-end"
                            render={x => x.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "")}
                        />
                        <Column
                            title="message.duration"
                            item="duration"
                            className="horizontal-end"
                            width="90px"
                        />
                    </Datatable>
                    }
                    {
                        isFilterEnabled &&
                    <FilteredList
                        onRowClick={onSelectFilter}
                        selected={grouped}
                    />
                    }
                    <Drawer className="sidebar drawer-container" _show={visible} _onClose={onClose}>
                        <OverflowContainer className={resolveClassNames("flex-item", styles.sidebarOverflow)}>
                            <MonitorForm message={message}/>
                        </OverflowContainer>
                    </Drawer>
                </div>
            </div>
            }
        </>
    );
};

Monitor.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.object,
    graph:    PropTypes.arrayOf(PropTypes.object)
};

export { Monitor };
