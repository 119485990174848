import React from "react";
import PropTypes from "prop-types";

const Accordeon = props => {
    const toggle = e => {
        if(props.disabled) return null;
        if(props.isOpen)   return props.onClose(e);

        return props.onOpen(e);
    };

    return (
        <div className={`${props.prefix} ${props.className}${props.isOpen ? " is-open" : ""}`}>
            <div className={`${props.prefix}-header`} onClick={toggle}>
                {
                    React.cloneElement(props.header, { disabled: props.disabled })
                }
            </div>
            <div className={`${props.prefix}-content flex-column`}>
                {
                    props.children
                }
            </div>
        </div>
    );
};

Accordeon.propTypes = {
    className: PropTypes.string,
    prefix:    PropTypes.string,
    isOpen:    PropTypes.bool,
    disabled:  PropTypes.bool,
    header:    PropTypes.node,
    onClose:   PropTypes.func,
    onOpen:    PropTypes.func,
    children:  PropTypes.node
};

Accordeon.defaultProps = {
    className: "",
    isOpen:    false,
    prefix:    "accordeon",
    header:    <div/>,
    onClose:   x => x,
    onOpen:    x => x
};

export { Accordeon };
