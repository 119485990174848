import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { PlantsDropdown } from "../PlantsDropdown";
import { MessageOverview } from "../MessageOverview";
import { Calendar } from "../Calendar";
import { SwitchButton, Icon, Drawer } from "@circle/kip-components";
import Slider from "rc-slider";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { useParams, useSearchParams } from "react-router-dom";
import { Plot } from "./Plot";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import icons from "../../enums/icons";
import names from "../../enums/names";
import { EvaluationForm } from "./EvaluationForm";
import { toMinuteString } from "../../helper/helper";
import { v4 as uuid } from "uuid";
import { isMessageType } from "../../helper/isMessageType";
import { usePrevious } from "../../hooks/previous";
import { CollapsedSlider } from "../collapsedSlider/CollapsedSlider";
import { sort } from "../../actions/files";

const Evaluation = props => { // eslint-disable-line max-statements
    const { translate }  = useTranslation();
    const { trigger }    = useTrigger();
    const params         = useParams();
    const [searchParams] = useSearchParams();
    const { plants, options, evaluationData, sortingSettings } = useBranch({
        plants:          ["plants"],
        options:         ["queryOptions"],
        evaluationData:  ["evaluation", "messages"],
        sortingSettings: ["sortingSettings"]
    });

    const [visible, setVisible]       = useState(false);
    const [selected, setSelected]     = useState(null); // eslint-disable-line
    const [messages, setMessages]     = useState([]);
    const [evaluation, setEvaluation] = useState({});
    const [isLoad, setIsLoad]         = useState(true);
    const prevValue = usePrevious({
        limit:       options.limit,
        type:        options.type,
        err:         options.selectedMessageTypes?.includes("err"),
        warn:        options.selectedMessageTypes?.includes("warn"),
        info:        options.selectedMessageTypes?.includes("info"),
        log:         options.selectedMessageTypes?.includes("log"),
        amount:      options.amount,
        avgDuration: options.avgDuration
    });

    const [filter] = useQueryString({
        trigger,
        options,
        plants:   plants,
        optional: {
            amount:      x => JSON.parse(x?.replace("undefined", "null") ?? JSON.stringify(options.amount)),
            avgDuration: x => JSON.parse(x?.replace("undefined", "null") ?? JSON.stringify(options.avgDuration)),
            limit:       x => isNaN(parseInt(x, 10)) ? 15 : parseInt(x, 10),
            type:        x => x ?? "frequency"
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["startTime", "messageType", "limit", "type", "amount", "avgDuration"],
        trigger
    });

    const sorted = sort({ items: messages, property: sortingSettings.selected, ordering: sortingSettings.order, getter: translate });

    useEffect(() => {
        if(!props.selected?.id) // eslint-disable-line react/prop-types
            return () => trigger("cleanup", ["evaluation", "messages"]);
        trigger("fetchEvaluation", props.selected?.id); // eslint-disable-line react/prop-types

        return () => trigger("cleanup", ["evaluation", "messages"]);
    }, [params.plantId, searchParams, props.selected?.id]); // eslint-disable-line react/prop-types

    useEffect(() => {
        setMessages(evaluationData.map(x => Object.assign({}, x, {
            id: uuid()
        })));
    }, [evaluationData]);

    const onRowClick = elem => {
        setVisible(true);
        setSelected(elem.id);
    };

    const onClose = () => {
        setVisible(false);
        setSelected(null);
    };

    const message = messages.find(x => x.id === selected);

    const evaluationSettings = e => {
        setEvaluation({ ...evaluation, [e]: !evaluation[e] });
    };

    const applyEvaluation = () => { // eslint-disable-line complexity
        trigger("applyOption", "type", evaluation.type);
        trigger("applyOption", "limit", evaluation.limit);
        trigger("applyOption", "amount", evaluation.amount);
        trigger("applyOption", "avgDuration", evaluation.avgDuration);
        if(isMessageType(evaluation, "err", options.selectedMessageTypes)) trigger("onMessageTypeFilter", "err");
        if(isMessageType(evaluation, "warn", options.selectedMessageTypes)) trigger("onMessageTypeFilter", "warn");
        if(isMessageType(evaluation, "info", options.selectedMessageTypes)) trigger("onMessageTypeFilter", "info");
        if(isMessageType(evaluation, "log", options.selectedMessageTypes)) trigger("onMessageTypeFilter", "log");
        if(evaluation.date) trigger("onCalendarSelect", evaluation.date.date);
        setIsLoad(true);
    };

    useEffect(() => {
        setEvaluation({
            limit:       options.limit,
            type:        options.type,
            err:         options.selectedMessageTypes?.includes("err"),
            warn:        options.selectedMessageTypes?.includes("warn"),
            info:        options.selectedMessageTypes?.includes("info"),
            log:         options.selectedMessageTypes?.includes("log"),
            amount:      options.amount,
            avgDuration: options.avgDuration
        });
    }, [options, options.selectedMessageTypes]);

    useEffect(() => {
        if(JSON.stringify(prevValue) === JSON.stringify(evaluation)) return setIsLoad(true);
        return setIsLoad(false);
    }, [evaluation]);

    return (
        <div className="evaluation-main flex-column">
            <div className="space-between">
                <div className="flex flex-grow">
                    <PlantsDropdown
                        selected={props.selected} // eslint-disable-line react/prop-types
                        onChange={id => trigger("onPlantSelect", id, "evaluation")}
                    />
                    <MessageOverview
                        onClick={evaluationSettings}
                        err={evaluation.err}
                        warn={evaluation.warn}
                        info={evaluation.info}
                        log={evaluation.log}
                    />
                </div>
                {
                    init &&
                    <div className="flex-row-reverse">
                        <Calendar
                            evaluation={true}
                            onSubmitEvaluation={e => setEvaluation({ ...evaluation, date: { date: e, type: e.name } })}
                        />
                    </div>
                }
            </div>
            <div className="mt-4">
                <div className="horizontal-end">
                    <CollapsedSlider
                        params={[evaluation.amount].flat()}
                        displayedValue={options.amount}
                        title={translate("graph.amount")}
                        onChange={e => setEvaluation({ ...evaluation, amount: e })}
                    />
                    <CollapsedSlider
                        params={[evaluation.avgDuration].flat()}
                        displayedValue={options.avgDuration}
                        title={translate("filter.average")}
                        onChange={e => setEvaluation({ ...evaluation, avgDuration: e })}
                    />
                    <div className="slider-container flex-column center mr-2">
                        <span>Top { evaluation.limit }</span>
                        <Slider
                            value={evaluation.limit}
                            min={0}
                            max={100}
                            onChange={x => setEvaluation({ ...evaluation, limit: x })}
                        />
                    </div>
                    <SwitchButton onChange={e => setEvaluation({ ...evaluation, type: e.target.value })} options={[
                        {
                            value:   "frequency",
                            checked: evaluation.type === "frequency",
                            label:   translate("evaluation.frequency")
                        },
                        {
                            value:   "duration",
                            checked: evaluation.type === "duration",
                            label:   translate("evaluation.duration")
                        }
                    ]} />
                </div>
            </div>
            {!isLoad &&
            <div className="update">
                <span><b>{translate("actions.evaluate")}</b></span>
                <button className="evaluation-button" onClick={applyEvaluation}><Icon className="menu-icon puls" _icon="Reload" /></button>
            </div>
            }
            <div className={!isLoad ? "blur" : ""}>
                <Plot/>
                <Datatable
                    content={sorted}
                    onHeadlineClick={x => trigger("onSorting", x)}
                    onRowClick={onRowClick}
                    selectedMsg={selected}
                >
                    <Column
                        title="message.messageType"
                        item="messageType"
                        width="100px"
                        sortable
                        filter="messageType"
                        render={x => <Icon className={names[x]} _icon={icons[x]}/>}
                    />
                    <Column
                        title="message.system"
                        item="system"
                        sortable
                        width="90px"
                        filter="system"
                    />
                    <Column
                        title="message.refObj"
                        item="referenceObject"
                        sortable
                        width="calc((100% - 600px) / 2)"
                        filter="referenceObject"
                    />
                    <Column
                        title="message.text"
                        item="message"
                        sortable
                        width="calc((100% - 500px) / 2)"
                        filter="message"
                    />
                    <Column
                        title="message.frequency"
                        item="frequency"
                        width="110px"
                        filter="duration"
                    />
                    <Column
                        title="message.avg_duration"
                        item="duration"
                        width="120px"
                        filter="duration"
                        render={x => toMinuteString(x)}
                    />
                </Datatable>
            </div>
            <div>
                <Drawer className="sidebar drawer-container" _show={visible} _onClose={onClose}>
                    {
                        visible &&
                        <EvaluationForm {...message } plant_id={props.selected?.id}/> // eslint-disable-line react/prop-types
                    }
                </Drawer>
            </div>
        </div>
    );
};

export { Evaluation };
