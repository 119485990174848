import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePrevious } from "../../hooks/previous";

const InfiniteList = props => { // eslint-disable-line max-statements
    const ref       = useRef();
    const wrapper   = useRef();
    const prevCount = usePrevious(props.children.length);

    const [blocked, setBlocked] = useState(false);

    const hasMore = props.children.length > 0 && (props.metadata.limit * props.metadata.page) < props.metadata.count && !blocked;
    const Element = typeof props.element === "string" || props.element instanceof String ? props.element : props.element.type;
    const loader  = props.loader();

    const isInViewport = () => {
        const wrapperRect = wrapper.current.getBoundingClientRect();

        if(wrapper.current.scrollHeight === wrapperRect.height) return true;
        if(!ref.current)                                        return null;

        const rect = ref.current.getBoundingClientRect();

        return wrapperRect.top + wrapperRect.height > rect.top;
    };

    const handleFetch = () => {
        if(blocked || !isInViewport()) return null;

        setBlocked(true);
        return props.onFetch();
    };

    const scrollTop = () => {
        wrapper.current.scrollTop = 0;
    };

    useEffect(() => {
        const cb = e => handleFetch(e);

        wrapper.current.addEventListener("resize", cb);
        wrapper.current.addEventListener("scroll", cb);

        return () => {
            if(!wrapper.current) return;

            wrapper.current.removeEventListener("resize", cb);
            wrapper.current.removeEventListener("scroll", cb);
        };
    }, [props.onFetch, blocked, setBlocked]);

    useEffect(() => {
        setBlocked(false);
        if(props.children.length > prevCount) return;

        scrollTop();
    }, [props.children.length]);

    useEffect(() => {
        setBlocked(false);
    }, [hasMore]);

    useEffect(() => {
        if(!isInViewport()) return;

        props.onFetch();
    }, [props.children.length]);

    return (
        <Element className={props.className} ref={wrapper}>
            {
                props.children.length > 0 &&
                props.children
            }
            {
                props.children.length <= 0 &&
                props.placeholder
            }
            {
                blocked &&
                <loader.type>{ loader.props.children }</loader.type>
            }
            {
                hasMore &&
                <loader.type ref={ref}>{ loader.props.children }</loader.type>
            }
        </Element>
    );
};

InfiniteList.defaultProps = {
    className:   "",
    onFetch:     x => x,
    element:     "div",
    loader:      () => <div>Lalilu</div>,
    placeholder: <div></div>,
    metadata:    {
        limit: 10,
        count: -1,
        page:  1
    }
};

InfiniteList.propTypes = {
    children: PropTypes.node,
    metadata: PropTypes.object,
    loader:   PropTypes.func,
    element:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onFetch:     PropTypes.func,
    className:   PropTypes.string,
    placeholder: PropTypes.object
};

export { InfiniteList };
