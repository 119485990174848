import { useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { Router as BrowserRouter, Route, Routes } from "react-router-dom";

const getOrigin = () => {
    const referrer = document.referrer.split("/");

    if(document.location.ancestorOrigins) return document.location.ancestorOrigins[0];
    if(document.referrer.length > 0)      return `${referrer[0]}//${referrer[2]}`;

    return null;
};

const Router = ({ history, ...props }) => {
    const [state, setState] = useState({
        action:   history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]); // eslint-disable-line

    useEffect(() => {
        const origin = getOrigin();

        if(!state.location.pathname || !origin) return;

        window.parent.postMessage({
            type: "redirect",
            id:   props.id,
            url:  `${state.location.pathname}${state.location.search}`
        }, origin);
    }, [state.location?.pathname, state.location?.search, props.id]);

    return (
        <BrowserRouter
            location={state.location}
            navigationType={state.action}
            navigator={history}
        >
            <Routes>
                <Route path="*" element={props.children}/>
            </Routes>
        </BrowserRouter>
    );
};

Router.propTypes = {
    children: PropTypes.node,
    history:  PropTypes.object,
    id:       PropTypes.string
};

export { Router };
