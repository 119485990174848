import PropTypes from "prop-types";
import { SwitchButton } from "@circle/kip-components";

const Filter = props => {
    const filterValues = [
        {
            value:   "filter",
            checked: props.isActive,
            label:   props.translate("monitor.filtered.view")
        },
        {
            value:   "chronologic",
            checked: !props.isActive,
            label:   props.translate("monitor.chronological.view")
        }
    ];

    return (
        <div className="filter-container flex relative">
            <SwitchButton className="switchFilter" options={filterValues} onChange={() => props.onSelect()} />
        </div>
    );
};

Filter.propTypes = {
    onSelect:  PropTypes.func,
    isActive:  PropTypes.bool,
    translate: PropTypes.func
};

Filter.defaultProps = {
    onSelect: x => x,
    isActive: false
};

export { Filter };
