import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { ProcessGraph } from "../monitor/ProcessGraph";
import icons from "../../enums/icons";
import types from "../../enums/types";
import { TextInput, TextArea, OverflowContainer } from "@circle/kip-components";
import { HistoryTable } from "../HistoryTable";

import styles from "./observationform.module.scss";
import { toMinuteString } from "../../helper/helper";
import { useParams } from "react-router-dom";

const ObservationForm = props => {
    const { trigger } = useTrigger();
    const params      = useParams();
    const { translate, currentLanguage } = useTranslation();
    const { history, historyChrono, calendar, graph } = useBranch({
        history:       ["sidebarHistory"],
        historyChrono: ["sidebarHistoryChrono"],
        calendar:      ["queryOptions", "calendar"],
        graph:         ["sidebarContent", "graph"]
    });
    const fromString = calendar.from.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");
    const toString   = calendar.until.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");

    const [tableSwitchFilter, setTableSwitchFilter] = useState("aggregated");

    useEffect(() => {
        if(!props.observation.referenceObject) return;

        const [sign, descr] = props.observation.referenceObject.split(" | ");

        trigger("fetchGraph", {
            plant_id:              params.plantId, // eslint-disable-line camelcase
            messageType:           props.observation.messageType,
            system:                props.observation.system,
            message:               props.observation.message,
            messageGroup:          "overall",
            referenceObject_Descr: descr, // eslint-disable-line camelcase,id-length
            referenceObject_Sign:  sign // eslint-disable-line camelcase,id-length
        });
    }, [props?.observation?.id, params]);

    useEffect(() => {
        const [sign, descr] = props.observation.referenceObject.split(" | ");
        const from    = calendar.from.getTime() / 1000;
        const until   = calendar.until.getTime() / 1000;

        trigger("retrieve", "history/list", ["sidebarContent", "history", "list"], {
            startTime:             `[${from},${until}]`,
            plant_id:              params.plantId ?? location.pathname.split("?")[0].split("/").slice(-1)[0], // eslint-disable-line camelcase
            messageType:           props.observation.messageType,
            system:                props.observation.system,
            message:               props.observation.message,
            referenceObject_Descr: descr, // eslint-disable-line camelcase,id-length
            referenceObject_Sign:  sign // eslint-disable-line camelcase,id-length
        });
    }, [props.observation.messageType, calendar]);

    return (
        <div className={styles.container}>
            <OverflowContainer>
                <div className={styles.headline}>
                    <span>{ translate("sidebar.headline").toUpperCase() }</span>
                    <span>{ fromString } - { toString }</span>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.messageType") }
                        </span>
                        <TextInput
                            className={`full-width input-message-${props.observation.messageType}`}
                            iconPosition="start"
                            icon={icons[props.observation.messageType]}
                            isReadonly
                            value={translate(types[props.observation.messageType])}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.system") }
                        </span>
                        <TextInput
                            className="full-width"
                            isReadonly
                            value={props.observation.system}
                        />
                    </div>

                </div>
                <div className={styles.row}>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.text") }
                        </span>
                        <TextArea
                            className="full-width"
                            readOnly={true}
                            value={props.observation?.message}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.refObj") }
                        </span>
                        <TextInput
                            className="full-width"
                            isReadonly
                            value={props.observation.referenceObject}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.avg_duration") }
                        </span>
                        <TextInput
                            isReadonly
                            value={props.observation.duration ? toMinuteString(props.observation.duration) : ""}
                        />
                    </div>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.frequency") }
                        </span>
                        <TextInput
                            isReadonly
                            value={props.observation.frequency}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowContent}>
                        <span className={styles.rowHead}>
                            { translate("message.process") }
                        </span>
                        <ProcessGraph data={graph}/>
                    </div>
                </div>
                <HistoryTable key={props.observation.referenceObject} onSwitchTableFilter={val => setTableSwitchFilter(val)} tableSwitchFilter={tableSwitchFilter} history={history} historyChrono={historyChrono}/>
            </OverflowContainer>
        </div>
    );
};

ObservationForm.propTypes = {
    observation: PropTypes.shape({
        messageType:     PropTypes.string,
        system:          PropTypes.string,
        message:         PropTypes.string,
        referenceObject: PropTypes.string,
        frequency:       PropTypes.number,
        id:              PropTypes.number,
        duration:        PropTypes.string,
        plant_id:        PropTypes.string // eslint-disable-line camelcase,
    }),
    message: PropTypes.object
};

ObservationForm.defaultProps = {
    onClose: x => x
};

export { ObservationForm };
