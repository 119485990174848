/* eslint-disable no-undefined */
import PropTypes from "prop-types";
import { ProgressBar } from "../generic/ProgressBar";
import { Tendency } from "../generic/Tendency";
import { toMinuteString } from "../../helper/helper";

import styles from "./progress.module.scss";

const Progress = props => {
    return (
        <div className={styles.progress}>
            {
                !props.hideBar &&
                <ProgressBar className={styles[`progress-${props.type}`]} value={props.isCount ? (props.ratio ?? 0) : (props.ratio ?? 0) * 100}/>
            }
            <div className={styles.tendency}>
                <span className={styles.content}>{ props.count !== undefined ? (props.count || "0") : toMinuteString(props?.duration) }</span>
                <Tendency value={props?.tendency}/>
            </div>
        </div>
    );
};

Progress.propTypes = {
    ratio:    PropTypes.number,
    isCount:  PropTypes.bool,
    count:    PropTypes.number,
    duration: PropTypes.number,
    tendency: PropTypes.number,
    type:     PropTypes.string,
    hideBar:  PropTypes.bool
};

export { Progress };
