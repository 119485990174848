import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { DatePicker } from "../types/DatePicker";
import { formatTimeSpan } from "../helper/formatTimeSpan";
import { Button, ButtonGroup, Icon, Modal, SwitchButton } from "@circle/kip-components";
import styles from "./calendar.module.scss";
import { DateTimePicker } from "./generic/dateTimePicker/DateTimePicker";
import { fromFakeUTC, toFakeUTC } from "../helper/helper";

const Calendar = props => {
    const { trigger } = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const { calendarData } = useBranch({
        calendarData: ["queryOptions", "calendar"]
    });
    const [calendar, setCalendar] = useState(false);
    const [value, setValue] = useState([calendarData.from, calendarData.until]);
    const [period, setPeriod] = useState(calendarData.name);

    const onSubmit = val => {
        const { from, until } = val;

        const newDate = DatePicker.of([new Date(fromFakeUTC(from.getTime())), new Date(fromFakeUTC(new Date(until).setUTCDate(until.getUTCDate())))]);

        setCalendar(false);
        setValue(newDate);

        if(props.evaluation) return props.onSubmitEvaluation(newDate);

        return trigger("onCalendarSelect", newDate);
    };

    const onClose = () => {
        setCalendar(false);
        setValue([calendarData.from, calendarData.until]);
    };

    const switchDate = e => {
        if(props.evaluation) {
            setPeriod(DatePicker.of(e.target.value).name);
            return props.onSubmitEvaluation(DatePicker.of(e.target.value));
        }
        return trigger("onCalendarSelect", DatePicker.of(e.target.value));
    };

    useEffect(() => {
        setValue([calendarData.from, calendarData.until]);
        setPeriod(calendarData.name);
    }, [calendarData]);

    return (
        <div className={styles.calendarContainer}>
            <span className={styles.datetimeLabel}>
                {formatTimeSpan(calendarData.from, calendarData.until, currentLanguage)}
            </span>
            <div className="space-around center">
                <div className="flex mr-2">
                    <ButtonGroup>
                        <Button _variant="icon" onClick={() => setCalendar(!calendar)}>
                            <Icon _icon="DatePicker" />
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="calendar-group">
                    <SwitchButton
                        onChange={e => switchDate(e)}
                        options={[
                            {
                                value:   "overall",
                                checked: props.evaluation ? period === "overall" : calendarData.name === "overall",
                                label:   <><span className="calendar_longtext">{translate("calendar.overall")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.overall.short")}</span></>
                            },
                            {
                                value:   "thisYear",
                                checked: props.evaluation ? period === "thisYear" : calendarData.name === "thisYear",
                                label:   <><span className="calendar_longtext">{translate("calendar.thisYear")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.thisYear.short")}</span></>
                            },
                            {
                                value:   "thisMonth",
                                checked: props.evaluation ? period === "thisMonth" : calendarData.name === "thisMonth",
                                label:   <><span className="calendar_longtext">{translate("calendar.thisMonth")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.thisMonth.short")}</span></>
                            },
                            {
                                value:   "last7Days",
                                checked: props.evaluation ? period === "last7Days" : calendarData.name === "last7Days",
                                label:   <><span className="calendar_longtext">{translate("calendar.last7Days")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.last7Days.short")}</span></>
                            },
                            {
                                value:   "today",
                                checked: props.evaluation ? period === "today" : calendarData.name === "today",
                                label:   <><span className="calendar_longtext">{translate("calendar.today")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.today.short")}</span></>
                            }
                        ]} />
                </div>
            </div>
            <Modal
                title={translate("modal.date")}
                isOpen={calendar}
                isCloseable={false}
                stopBackgroundPropagation={false}
                onClose={() => setCalendar(false)}
            >
                <DateTimePicker
                    onChange={onSubmit}
                    onCancel={onClose}
                    locale={currentLanguage}
                    value={{
                        from:  toFakeUTC(value[0]),
                        until: toFakeUTC(value[1])
                    }}
                />
            </Modal>
        </div>
    );
};

Calendar.defaultProps = {
    evaluation: false
};

Calendar.propTypes = {
    onSubmitEvaluation: PropTypes.func,
    evaluation:         PropTypes.bool
};

export { Calendar };
