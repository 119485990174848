interface Manufacturer {
    id: string,
}

interface WorkingShift {
    id: string
}

interface PlantData {
    id: string | number,
    name: string,
    image: string,
    location: string,
    manufacturer: Manufacturer,
    workingShifts: WorkingShift[]
}

interface Body {
    plant_id: string | number,
    messageType: string
}

interface Message {
    messageBody: Body,
    startTime: string,
    endTime: string
}

interface IPlant {
    id: string | number,
    name: string,
    image: string,
    location: string,
    duration: Date,
    last7Days: number,
    lastMessage: Date,
    manufacturer: Manufacturer
}

class Plant implements IPlant {
    constructor(plant: PlantData, msgs: Message[]) { // eslint-disable-line
        const messages = msgs.filter(x => x.messageBody?.plant_id === plant.id && x.messageBody?.messageType === "err")
            .map(x => Object.assign({}, x, {
                startTime: new Date(x.startTime)
            }))
            .filter(x => x.startTime.getTime() >= (Date.now() - 604800000) && x.startTime.getTime() <= Date.now());

        this.id           = plant.id;
        this.name         = plant.name;
        this.image        = plant.image;
        this.location     = plant.location;
        this.manufacturer = plant.manufacturer;
        this.duration     = new Date(messages.reduce((dest, val) => dest + (new Date(val.endTime).getTime() - new Date(val.startTime).getTime()), 0));
        this.last7Days    = messages.length;
        this.lastMessage  = messages.length > 0 ? new Date(messages[messages.length - 1].startTime) : new Date();
        this.shifts       = plant.workingShifts;
    }
    id: string | number;
    name: string;
    image: string;
    location: string;
    duration: Date;
    last7Days: number;
    lastMessage: Date;
    manufacturer: Manufacturer;
    shifts: WorkingShift[];

    clone() {
        return structuredClone(this);
    }
}

export { Plant };
