import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@circle/kip-components";
import styles from "./collapsedSlider.module.scss";
import { resolveClassNames, useOutsideClickHandler } from "palstek";
import { TextInput } from "@circle/kip-components";

const CollapsedSlider = props => { // eslint-disable-line complexity
    const [timer, setTimer] = useState(null);
    const [open, setOpen]   = useState(false);
    const [value, setValue] = useState(props.params);

    const outsideClickHandler = (e, targetRef) => {
        if(window.getSelection() && targetRef?.contains(window.getSelection().anchorNode)) return;

        setOpen(!open);
    };
    const [ref] = useOutsideClickHandler(outsideClickHandler, open);

    const onChange = (def, val) => {
        if(timer) clearTimeout(timer);

        const getReturnValue = (values, validationExpr) => {
            if(!values.val || validationExpr.test(values.val)) return values.params;
            if(values.def === "minValue")                      return [values.val, values.value[1]];
            if(values.def === "maxValue")                      return [values.value[0], values.val];

            return values.val;
        };

        const regExp = /^0[0-9].*$/;

        const values = {
            val, def, value,
            params: props.params
        };

        const returnValues = getReturnValue(values, regExp);

        if(val && !regExp.test(val)) setValue(returnValues);

        const func = setTimeout(() => {
            props.onChange(returnValues);
        }, 750);

        setTimer(func);
    };

    useEffect(() => {
        setValue(props.params);
    }, [props.params]);

    return (
        <div ref={ref} className={styles.container}>
            <div
                className={styles.collapsedSliderContainer}
                onClick={e => {
                    e.preventDefault();
                    setOpen(!open);
                }}
            >
                <span><b>{props.title}</b></span>
                {(props.displayedValue[0] !== 0 && props.displayedValue[1] !== 999999999999999 || props.displayedValue[0] === 0 && props.displayedValue[1] !== 999999999999999 || props.displayedValue[0] !== 0 && props.displayedValue[1] === 999999999999999) &&
                <>
                    <span className={styles.container}>
                        <div className={styles.textContainer}><b>{`${props.displayedValue[0]}`}</b></div>
                        <b className={styles.divider}>–</b>
                        <div className={styles.textContainer}><b className={styles.textContainer}>{`${props.displayedValue[1] === 999999999999999 ? "∞" : props.displayedValue[1]}`}</b></div>
                    </span>
                    <button
                        className={styles.close}
                        onClick={e => {
                            e.preventDefault();
                            props.onChange([0, 999999999999999]);
                        }}>
                        <Icon _icon="Close" />
                    </button>
                </>
                }

                <Icon _icon="ChevronDown" className={resolveClassNames(styles.icon, open && styles.open)}/>
            </div>

            <div className={resolveClassNames(styles.dropdownSection, !open && styles.hidden)}>
                <div className={styles.inputSection}>
                    <TextInput
                        min={0}
                        max={Infinity}
                        name="minValue"
                        type="number"
                        value={value[0]}
                        onChange={e => onChange("minValue", e.target.value)}
                    />
                    <span className={styles.marginRight}>–</span>
                    <TextInput
                        min={0}
                        max={Infinity}
                        type="number"
                        name="maxValue"
                        value={value[1] === 999999999999999 ? null : value[1]}
                        onChange={e => onChange("maxValue", e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

CollapsedSlider.propTypes = {
    title:          PropTypes.string,
    params:         PropTypes.array,
    onChange:       PropTypes.func,
    displayedValue: PropTypes.array
};

export { CollapsedSlider };
