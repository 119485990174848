import { hasPermission, useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Accordeon } from "../generic/Accordeon";
import enumTypes from "../../enums/types";
import PropTypes from "prop-types";
import { InfiniteList } from "../generic/InfiniteList";
import { Aggregate } from "./Aggregate";
import { ListHeader as Header } from "./ListHeader";
import { Icon } from "@circle/kip-components";
import { useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { usePrevious } from "palstek";
import { toMinuteString } from "../../helper/helper";

const transform = x => {
    if(!(x instanceof Object)) return (x ?? "").toString();

    return toMinuteString((((x.days || 0) * 24 + (x.hours || 0)) * 60 + (x.minutes || 0)) * 60 + (x.seconds || 0));
};

const FilteredList = props => { // eslint-disable-line complexity
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const params        = useParams();
    const { monitor, pagination, sorting, isOpen, user, options, monitorStatistic } = useBranch({ // eslint-disable-line @typescript-eslint/no-unused-vars
        monitor:          ["monitor"],
        pagination:       ["pagination", "monitor"],
        sorting:          ["monitor", "sorting"],
        isOpen:           ["monitor", "isOpen"],
        user:             ["user"],
        options:          ["queryOptions"],
        monitorStatistic: ["monitorStatistic"]
    });
    const prevOpts = usePrevious(options);

    const groups = ["err", "task", "warn", "info", "system", "log"]
        .map(x => monitor?.groups?.find(y => y.messageType === x))
        .filter(elem => options.messageTypes.includes(elem?.messageType));

    const onOpen = messageType => {
        trigger("toggleMessageType", messageType);
        trigger("fetch", params?.plantId, { onlyDatatable: true, reset: true }, params.messageGroup);
    };

    const onFetch = useCallback(messageType => {
        if(isOpen.filter(x => messageType === x).length <= 0) return;

        trigger("fetch", params?.plantId, { onlyDatatable: true }, params.messageGroup);
    }, [isOpen, params]);

    useEffect(() => {
        const isLazy = prevOpts?.messageTypes?.length !== options?.messageTypes?.length;

        trigger("fetch", params?.plantId, { onlyDatatable: true, reset: true }, params.messageGroup, isLazy);
    }, [options, params?.plantId]);

    return (
        <div>
            <div className="filtered-list-header">
                <div className="accordeon-row">
                    <div className="accordeon-cell font-bold">
                        <span title={translate("message.messageType")}>{ translate("message.messageType") }</span>
                    </div>
                    <div className="accordeon-cell font-bold  vertical-align clickable" onClick={() => trigger("sort", params.plantId, params.messageGroup, {
                        property: "system",
                        order:    sorting.order !== "desc" ? "desc" : "asc"
                    })}>
                        {
                            sorting.property === "system" &&
                            <Icon className="filter-arrow" _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                        }
                        <span title={translate("message.system")}>{ translate("message.system") }</span>
                    </div>
                    <div className="accordeon-cell font-bold vertical-align clickable" onClick={() => trigger("sort", params.plantId, params.messageGroup, {
                        property: "referenceObject",
                        order:    sorting.order !== "desc" ? "desc" : "asc"
                    })}>
                        {
                            sorting.property === "referenceObject" &&
                            <Icon className="filter-arrow" _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                        }
                        <span title={translate("message.refObj")}>{ translate("message.refObj") }</span>
                    </div>
                    <div className="accordeon-cell font-bold vertical-align clickable" onClick={() => trigger("sort", params.plantId, params.messageGroup, {
                        property: "message",
                        order:    sorting.order !== "desc" ? "desc" : "asc"
                    })}>
                        {
                            sorting.property === "message" &&
                            <Icon className="filter-arrow" _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                        }
                        <span title={translate("message.text")}>{ translate("message.text") }</span>
                    </div>
                    <div className="accordeon-cell font-bold horizontal-end vertical-align clickable" onClick={() => trigger("sort", params.plantId, params.messageGroup, {
                        property: "count",
                        order:    sorting.order !== "desc" ? "desc" : "asc"
                    })}>
                        {
                            sorting.property === "count" &&
                            <Icon className="filter-arrow" _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                        }
                        <span title={translate("filter.count")}>{ translate("filter.count") }</span>
                    </div>
                    <div className="accordeon-cell font-bold horizontal-end vertical-align clickable" onClick={() => trigger("sort", params.plantId, params.messageGroup, {
                        property: "duration",
                        order:    sorting.order !== "desc" ? "desc" : "asc"
                    })}>
                        {
                            sorting.property === "duration" &&
                            <Icon className="filter-arrow" _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                        }
                        <span title={translate("filtered_list.duration")}>{ translate("filtered_list.duration") }</span>
                    </div>
                    <div className="accordeon-cell font-bold horizontal-end vertical-align clickable" onClick={() => trigger("sort", params.plantId, params.messageGroup, {
                        property: "average",
                        order:    sorting.order !== "desc" ? "desc" : "asc"
                    })}>
                        {
                            sorting.property === "average" &&
                            <Icon className="filter-arrow" _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                        }
                        <span title={translate("filtered_list.avg_duration")}>{ translate("filtered_list.avg_duration") }</span>
                    </div>
                </div>
            </div>
            <div>
                {
                    groups.filter(x => x.messageType !== "log" || hasPermission(user, "MESSAGE_MONITOR_ADMIN")).map((elem, idx) => (
                        <Accordeon
                            key={idx}
                            disabled={parseInt(elem.count ?? FilteredList.defaultValues.count, 10) <= 0}
                            isOpen={isOpen.filter(x => elem.messageType === x).length > 0}
                            header={(
                                <Header
                                    name={translate(enumTypes[elem.messageType])}
                                    count={elem.count ?? FilteredList.defaultValues.count }
                                />
                            )}
                            onOpen={() => onOpen(elem.messageType)}
                            onClose={() => trigger("toggleMessageType", elem.messageType)}
                        >
                            <InfiniteList
                                className="accordeon-list flex-column"
                                metadata={pagination[elem.messageType]}
                                loader={() => <div><span>Loading...</span></div>}
                                onFetch={() => onFetch(elem.messageType)}
                            >
                                {
                                    monitor.aggregates[elem.messageType].map((x, index) => {
                                        return (
                                            <Aggregate
                                                key={index}
                                                {...x}
                                                reference={`${x.referenceObject_Sign} | ${x.referenceObject_Descr}`}
                                                className={`${props.selected === x.id ? "selected" : ""}`}
                                                count={x.count ?? FilteredList.defaultValues.count}
                                                duration={transform(x.duration ?? FilteredList.defaultValues.duration)}
                                                average={transform(x.average ?? FilteredList.defaultValues.average)}
                                                onClick={() => props.onRowClick(x)}
                                            />
                                        );
                                    })
                                }
                            </InfiniteList>
                        </Accordeon>
                    ))
                }
            </div>
        </div>
    );
};

FilteredList.propTypes = {
    onRowClick: PropTypes.func,
    selected:   PropTypes.string,
    content:    PropTypes.arrayOf(PropTypes.object)
};

FilteredList.defaultValues = {
    count:    0,
    duration: "-",
    average:  "-"
};


export { FilteredList };
