import React from "react";
import PropTypes from "prop-types";
import names from "../../enums/names";
import { resolveClassNames } from "palstek";

import styles from "./tooltip.module.scss";

const typeMap = {
    err:    ["errorDuration", "errors"],
    task:   ["taskDuration", "tasks"],
    warn:   ["warningDuration", "warnings"],
    info:   [null, "infos"],
    system: [null, "systems"],
    log:    [null, "logs"]
};

const Tooltip = ({ active, payload, label, isFrequency, selectedMessageTypes }) => {
    const formatDuration = seconds => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const filteredData =  Object.keys(typeMap).map(category => {
        return {
            type:     category,
            duration: payload?.find(item => item.dataKey === typeMap[category][0])?.value,
            count:    payload?.find(item => item.dataKey === typeMap[category][1])?.value
        };
    }).filter(x => isFrequency && x.count || x.duration).filter(x => selectedMessageTypes?.includes(x.type));

    return (
        <>
            {
                active && payload && payload.length &&
                <div className={filteredData.some(ele => ele.duration !== 0 && ele.count !== 0) ? "custom-tooltip" : styles.hidden}>
                    <div className="tooltip-label">{`${label}`}</div>
                    <div className={styles.durationTooltip}>
                        {
                            filteredData.map((ele, key) => (
                                <React.Fragment key={key}>
                                    <span className={resolveClassNames(names[ele.type], styles.tooltipIcon)}>&#11044;</span>
                                    <span>{ ele?.duration ? `${formatDuration(ele?.duration)} min` : "-"}</span>
                                    {isFrequency ?
                                        <span className="ml-1">{ele.count ? ele.count : "-"}</span> : <span></span>
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    );
};

Tooltip.propTypes = {
    active:      PropTypes.bool,
    isFrequency: PropTypes.bool,
    payload:     PropTypes.arrayOf(PropTypes.object),
    label:       PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    selectedMessageTypes: PropTypes.array
};

export { Tooltip };
