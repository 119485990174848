import { useTranslation } from "@circle/gestalt-app";
import { Icon } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import PropTypes from "prop-types";
import Icons from "../../enums/icons";

import styles from "./typeselector.module.scss";

const TypeSelector = props => {
    const { translate } = useTranslation();

    return (
        <div className={resolveClassNames(styles.selector, !props.active && styles.inactive)} onClick={props.onClick}>
            <Icon className={resolveClassNames(styles.icon, styles[`type-${props.type}`])} _icon={Icons[props.type]} />
            <span>{ translate(`overview.type.${props.type}`) }</span>
        </div>
    );
};

TypeSelector.defaultProps = {
    onClick: x => x
};

TypeSelector.propTypes = {
    type:    PropTypes.string,
    active:  PropTypes.bool,
    onClick: PropTypes.func
};

export { TypeSelector };
