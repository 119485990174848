import { useBranch, useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { DropDownSelect } from "@circle/kip-components";
import styles from "./plantsDropdown.module.scss";

const PlantsDropdown = props => {
    const { translate } = useTranslation();
    const { selected, plants } = useBranch({
        selected: ["selectedPlant"],
        plants:   ["licensedPlants"]
    });
    const plantOptions = plants?.map(x => ({
        value: x.id,
        label: <span key={x.id} className={styles.itemLabel}>
            <img src={x.manufacturer?.logo ?? "/images/Bitmap.png"} loading="lazy" alt="Manufacturer Logo" />
            <span>
                <b>{translate(x?.name) || ""}</b>
                <br />
                {translate(x.location)}
            </span>
        </span>,
        checked: props.isSelected && selected?.id === x?.id
    }));

    const onSelect = options => {
        const checkedElem = options.find(x => x.checked);
        const plant       = plants.find(x => x.id === checkedElem?.value);

        if(!plant?.id) return;

        props.onChange(plant.id);
    };

    return (
        <DropDownSelect
            className={styles.dropdown}
            closeOnOptionClick
            options={plantOptions}
            // eslint-disable-next-line no-undefined
            customLabel={plantOptions.find(x => x.checked) ? undefined : (
                <span key={"fallback"} className={styles.itemLabel}>
                    <img src={"/images/Bitmap.png"} loading="lazy" alt="Fallback Logo" />
                    <span>
                        <b>{translate([{
                            language: "de",
                            content:  "Anlage auswählen"
                        }, {
                            language: "en",
                            content:  "Choose a plant"
                        }]) || ""}</b>
                        <br />
                        {translate([{
                            language: "de",
                            content:  ""
                        }, {
                            language: "en",
                            content:  ""
                        }])}
                    </span>
                </span>
            )}
            onChange={onSelect}
        >
        </DropDownSelect>
    );
};

PlantsDropdown.propTypes = {
    onChange:   PropTypes.func,
    isSelected: PropTypes.bool
};

PlantsDropdown.defaultProps = {
    isSelected: true
};

export { PlantsDropdown };
