import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";
import md5 from "md5";
import styles from "./causeForm.module.scss";
import { Button, ComboBox, TextArea, Icon } from "@circle/kip-components";
import { Checkbox } from "../generic/Checkbox";
import { DateTimePicker } from "../generic/dateTimePicker/DateTimePicker";
import { fromFakeUTC, toFakeUTC } from "../../helper/helper";
import { CauseHeader } from "./CauseHeader";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import icons from "../../enums/icons";
import names from "../../enums/names";
import { EditFileModal } from "../generic/editFileModal/EditFileModal";

const validate = () => ({
    isValid: true
});

const validateRequired = content => {
    return content.name?.length > 0 && content.category?.length > 0 && content.symptoms?.length > 0;
};

const CauseForm = props => { // eslint-disable-line max-statements, complexity
    const { trigger } = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const { routines, causes, categories, messagesData } = useBranch({
        routines:     ["routinesOfPlant"],
        causes:       ["cause_reports"],
        categories:   ["cause_categories"],
        messagesData: ["causeMessages"]
    });
    const init = {
        name:        props.data?.name || "",
        language:    currentLanguage,
        description: props.data?.description || "",
        category:    props.data?.category || [],
        plant_id:    props.selectedPlant.id, // eslint-disable-line camelcase
        symptoms:    props.data?.symptoms || [],
        routines:    props.data?.routines || [],
        startTime:   props.data?.startTime || new Date(new Date().setUTCHours(0, 0, 0, 0)),
        endTime:     props.data?.endTime || new Date(new Date(new Date().setUTCHours(23, 59, 59, 999)).setDate(new Date().getDate() + 1)),
        messages:    props.data?.messages || [],
        img:         props.data?.img || []
    };

    const [content, setContent]             = useState(init); //eslint-disable-line
    const [valid, setValid]                 = useState({});
    const [hash]                            = useState(md5(JSON.stringify(init)));
    const [allCategories, setAllCategories] = useState(categories.map(x => ({
        ...x,
        checked: false
    })));

    const onChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };

        const currentHash = md5(JSON.stringify(values));

        setContent(values);

        props.onChange(key, values[key], currentHash !== hash);
    };

    const onCheck = (value, target) => {
        const findCategory = allCategories.find(x => x.id === value.id);
        const newValue = {
            ...findCategory,
            category_id: value.id, // eslint-disable-line camelcase
            checked:     target
        };
        const values = allCategories.concat([]).map(x => x.id === newValue.category_id ? newValue : x);

        setAllCategories(values);
        const diffCategories = values.filter(x => x.checked === true);
        const finalCategories = diffCategories.map(x => ({
            category_id: x.category_id, // eslint-disable-line camelcase
            checked:     x.checked
        }));

        return onChange("category", finalCategories);
    };

    const routinesOptions = routines?.map(x => ({
        value: translate(x.name),
        label: translate(x.name),
        id:    x.id
    }));
    const routinesValues = content.routines?.map(x => translate(routines.find(y => y.id === x.routine_id)?.name));

    const onRoutineSelect = selection => {
        if(selection === undefined) return null; // eslint-disable-line no-undefined
        const selected = selection
            .map(x => routinesOptions.find(y => y.label === x))
            .filter(x => x)
            .map(x => ({
                routine_id: x.id // eslint-disable-line camelcase
            }));

        return onChange("routines", selected);
    };

    const messages = props.isEditable ?
        messagesData.map(x => ({ ...x, checked: Boolean(content.messages.find(y => x.id === y.id)) })) :
        content.messages.map(x => ({ ...x, checked: true }));
    const causesOptions = [...new Set(causes.map(x => x.name))].map(x => ({
        value: x,
        label: x
    }));
    const onNameChange = selection => {
        if(selection === undefined) return null; // eslint-disable-line no-undefined

        return onChange("name", selection[0]);
    };

    const uniqueNames = new Set();
    const symptomsList = causes.map(x => x.symptoms).flat();
    const uniqueSymptoms = symptomsList.filter(element => {
        const isDuplicate = uniqueNames.has(element.content);

        uniqueNames.add(element.content);

        if(!isDuplicate) return true;

        return false;
    });
    const symptomsOptions = uniqueSymptoms
    .map(x => x.language === currentLanguage ? ({
        value: x?.content,
        label: x?.content
    }) : <></>);
    const onSymptomsChange = (key, values) => {
        if(values.length === 0) return;
        const symptomsAll = values.map(x => ({
            content:  x,
            language: currentLanguage
        }));

        return onChange(key, symptomsAll); // eslint-disable-line consistent-return
    };
    const symptomValues = content.symptoms.concat([]).sort((a, b) => a < b ? -1 : 1).map(x => x.content);

    const onDateSelect = ({ from, until }) => {
        setContent({
            ...content,
            startTime: fromFakeUTC(from),
            endTime:   fromFakeUTC(until),
            messages:  content.messages.filter(x => fromFakeUTC(from) <= new Date(x.startTime) && new Date(x.startTime) <= fromFakeUTC(until))
        });
    };

    const onMessageChange = (id, e) => {
        e.stopPropagation();
        e.preventDefault();

        if(!props.isEditable) return null;

        const previous = [...content.messages];
        const index    = previous.findIndex(x => x.id === id);

        if(index < 0) return onChange("messages", previous.concat(messagesData.find(x => x.id === id)));

        return onChange("messages", previous.filter(x => x.id !== id));
    };

    const onSubmitCause = () => {
        if(!valid.overall)  return null;
        return props.onSubmit(content);
    };

    const onAddImage = value => {
        const allMedia = content?.img?.map(x => x);


        onChange("img", allMedia.concat({ url: value }));
    };

    const onImgDelete = item => {
        const allMedia = [...content?.img ?? []];

        allMedia.splice(item, 1);

        onChange("img", allMedia);
    };

    useEffect(() => {
        const startTime = content.startTime instanceof Date ? content.startTime : new Date(content.startTime);
        const endTime   = content.endTime instanceof Date ? content.endTime : new Date(content.endTime);

        if(isNaN(startTime.getTime()) || isNaN(endTime.getTime())) return;

        trigger("pullFilteredMessages", { startTime: content.startTime, endTime: content.endTime, plantId: props.selectedPlant.id });
    }, [content.startTime, content.endTime]);

    useEffect(() => {
        const required  = validateRequired(content);
        const validated = validate();
        const overall   = required && validated.isValid;

        setValid({
            required,
            overall
        });
    }, [content]);

    return (
        <>
            <div className="event-block flex-column">
                <div className="cause-heading flex">
                    <div className="overview-item-heading-block cause-plant mb-2 pd-20 flex">
                        <img src={props.selectedPlant.image ?? "/images/Bitmap.png"} loading="lazy" alt="" className="bitmap"/>
                        <div className="overview-item-heading">
                            <div className="text-block-primary">
                                <span>
                                    {
                                        translate(props.selectedPlant.name)?.length > 24 ?
                                            `${translate(props.selectedPlant.name).substring(0, 24)}...` :
                                            translate(props.selectedPlant.name)
                                    }
                                </span>
                            </div>
                            <div className="text-block-secondary">
                                <span>{translate(props.selectedPlant.location)}</span>
                            </div>
                        </div>
                    </div>
                    <CauseHeader />
                </div>
            </div>

            <div className={styles.form}>
                <div className={styles.content}>
                    <span className={styles.formHeader}>{translate("overview.report.cause.name")}*</span>
                    <ComboBox
                        id="name"
                        value={content.name}
                        onChange={onNameChange}
                        isEnabled={props.isEditable}
                        options={causesOptions.concat([]).sort((a, b) => a - b)}
                        className={resolveClassNames("full-width", styles.input)}
                        isMulti={false}
                    />
                </div>
                <span className={styles.formHeader}>{translate("overview.report.cause.category")}*</span>
                <div className={styles.content}>
                    <div className="flex mt-2 ml-2">
                        {
                            categories
                                .filter(x => x.language === currentLanguage)
                                .map(x => (
                                    <Checkbox disabled={!props.isEditable} key={x.id} checked={Boolean(content.category?.find(y => y.category_id === x.id))} onChange={e => onCheck(x, e.target.checked)}>
                                        <b>{x.name}</b>
                                    </Checkbox>
                                ))
                        }
                    </div>
                </div>
                <div className={styles.content}>
                    <span className={styles.formHeader}>{translate("overview.report.cause.description")}</span>
                    <TextArea
                        className={resolveClassNames("full-width", styles.input)}
                        id="description"
                        readOnly={!props.isEditable}
                        value={content.description}
                        onChange={e => onChange("description", e.target.value)}
                    />
                </div>
                <div className={styles.content}>
                    <span className={styles.formHeader}>{translate("overview.report.cause.symptom")}*</span>
                    <ComboBox
                        id="symptom_select"
                        isEnabled={props.isEditable}
                        className={resolveClassNames("full-width", styles.input)}
                        options={symptomsOptions.concat([]).sort((a, b) => a < b ? -1 : 1)}
                        value={symptomValues}
                        isMulti
                        triggerOnChangeOnOptionsChange={false}
                        onChange={onSymptomsChange.bind(this, "symptoms")}
                    />
                </div>
                <div className={styles.content}>
                    <span className={styles.formHeader}>{translate("overview.report.cause.routine")}</span>
                    <ComboBox
                        id="routines_select"
                        isEnabled={props.isEditable}
                        className={resolveClassNames("full-width", styles.input)}
                        options={routinesOptions.concat([]).sort((a, b) => a < b ? -1 : 1)}
                        value={routinesValues.concat([]).sort((a, b) => a < b ? -1 : 1)}
                        isMulti={true}
                        onChange={onRoutineSelect}
                    />
                </div>
                <div className={styles.content}>
                    {props.isEditable &&
                <>
                    <span className={styles.formHeader}>{translate("time.headline")}*</span>
                    <div className={styles.input}>
                        <DateTimePicker
                            isModalView={false}
                            hasToggleEnabled={false}
                            onChange={onDateSelect}
                            isReadonly={!props.isEditable}
                            locale={currentLanguage}
                            value={{
                                from:  toFakeUTC(content.startTime),
                                until: toFakeUTC(content.endTime)
                            }}
                        />
                    </div>
                </>
                    }
                </div>
                <section className={styles.content}>
                    <span className={styles.formHeader}>
                        { translate("upload.picture") }
                    </span>
                    <div className="flex-row imgContainer">
                        {content?.img?.map((x, key) =>
                            <EditFileModal
                                isModalEnable={true}
                                key={key}
                                file={x.url}
                                readOnly={!props.isEditable}
                                onImageChange={() => onImgDelete(key)}
                            />
                        )}
                        {props.isEditable &&
                                <EditFileModal
                                    isModalEnable={true}
                                    file={null}
                                    onImageChange={(key, value) => onAddImage(value, key)}
                                />
                        }
                    </div>
                </section>

                <div className={styles.content}>
                    <span className={styles.formHeader}>{translate("overview.report.cause.messages")}</span>
                    <div className={styles.input}>
                        <Datatable
                            className="cause-messages"
                            onRowClick={(x, e) => onMessageChange(x.id, e)}
                            content={messages}
                            refs={["messageType", "system", "refObj", "message", "startTime"]}
                        >
                            <Column
                                title=""
                                width="10%"
                                render={x => (
                                    <Checkbox className="ml-2" checked={x.checked} onChange={onMessageChange.bind(this, x.id)}/>
                                )}
                            />
                            <Column
                                title="cause.table.type"
                                item="messageType"
                                width="10%"
                                render={x => <Icon className={names[x]} _icon={icons[x]}/>}
                            />
                            <Column
                                title="cause.table.system"
                                item="system"
                                width="20%"
                            />
                            <Column
                                title="cause.table.referenceObject"
                                width="30%"
                                render={x => `${x.referenceObject_Sign} | ${x.referenceObject_Descr}`}
                            />
                            <Column
                                title="cause.table.message"
                                item="message"
                                width="30%"
                            />
                        </Datatable>
                    </div>
                </div>
            </div>

            <section className="form-button-group event-form-actions">
                <Button onClick={props.onClose}>
                    <span>{translate(props.isEditable ? "actions.cancel" : "actions.close")}</span>
                </Button>
                {
                    props.isEditable &&
                    <Button
                        _appearance="primary"
                        className="ml-2"
                        disabled={!(valid.overall && content.endTime > content.startTime)}
                        onClick={onSubmitCause}
                    >
                        <span>{translate("actions.save")}</span>
                    </Button>
                }
            </section>
        </>
    );
};

CauseForm.propTypes = {
    selectedPlant: PropTypes.object,
    onChange:      PropTypes.func,
    onSubmit:      PropTypes.func,
    onClose:       PropTypes.func,
    data:          PropTypes.object,
    isEditable:    PropTypes.bool
};

CauseForm.defaultProps = {
    onChange:   x => x,
    onSubmit:   x => x,
    onClose:    x => x,
    isEditable: true
};

export { CauseForm };
